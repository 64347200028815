import { createStore } from 'vuex';

export default new createStore({
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'dark',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',

        is_loading: false,
        devices_data: null,
        device_with_info: null,

        is_loading: false,
        temp_cards_data: null,
        cards_data: null,
        one_card: null,
        search: '',

        temp_cards_replenishment: null,
        cards_replenishment_date_sort: null,

        start_date_device_operations: null || '2022-01-01',
        end_date_device_operations: null || Date.now(),

        action_key_list_cards_filter_date: '',
        list_cards_filter_date: [],
    },

    getters: {
        layout(state) {
            return state.layout;
        },

        getLoader(state) {
            return state.is_loading;
        },

        // devices
        devicesData(state) {
            return state.devices_data;
        },

        deviceWithInfo(state) {
            return state.device_with_info;
        },

        // cards
        cardsData(state) {
            return state.cards_data;
        },

        oneCard(state) {
            return state.one_card;
        },

        getSearch(state) {
            return state.search;
        },

        // CardReplenishment
        cardsRDateSort(state) {
            return state.cards_replenishment_date_sort;
        },

        actionKeyListCardsFilterDate(state) {
            return state.action_key_list_cards_filter_date;
        },

        listCardsFilterDate(state) {
            return state.list_cards_filter_date;
        },

        startDateDeviceOperations(state) {
            return state.start_date_device_operations;
        },

        endDateDeviceOperations(state) {
            return state.end_date_device_operations;
        },
    },

    mutations: {
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleLoader(state, payload) {
            state.is_loading = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        // devices
        setDevicesData(state, value) {
            state.devices_data = value;
        },
        setDeviceWithInfo(state, value) {
            state.device_with_info = value;
        },
        // cards
        cardsData(state, value) {
            state.cards_data = value;
            state.temp_cards_data = value;
        },
        setOneCard(state, value) {
            state.one_card = value;
        },
        // CardReplenishment
        setTempCardsRData(state, value) {
            state.temp_cards_replenishment = value;
        },
        setCardsRDateSort(state, value) {
            state.cards_replenishment_date_sort = value;
        },
        setActionKeyListCardsFilterDate(state, key) {
            state.action_key_list_cards_filter_date = key;
        },
        setListCardsFilterDate(state, key) {
            state.list_cards_filter_date = key != '' ? state.cards_replenishment_date_sort[key].list : [];
        },
        setActiveCardsRDateSort(state, key) {
            Object.keys(state.cards_replenishment_date_sort).forEach((k) => {
                state.cards_replenishment_date_sort[k].active = false;
            });
            if (key != '') {
                state.cards_replenishment_date_sort[key].active = true;
            }
        },
        startDateDeviceOperations(state, date) {
            state.start_date_device_operations = date;
        },
        endDateDeviceOperations(state, date) {
            state.end_date_device_operations = date;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'dark';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },

    actions: {
        // All Devices -----------------------------------------------------------------
        async getDevices({ commit }, $json) {
            commit('toggleLoader', true);

            const response = await $json.get('device');

            if (response.statusCode === 200) {
                commit('setDevicesData', [...response.json]);
            }

            commit('toggleLoader', false);
        },

        // Device ----------------------------------------------------------------------
        async getDeviceWithInfo({ commit }, data) {
            commit('toggleLoader', true);

            const { $json, id } = data;

            if (id === 'new') {
                commit('setDeviceWithInfo', {});
            } else {
                const response = await $json.get(`device/info/${id}`);

                if (response.statusCode === 200) {
                    commit('setDeviceWithInfo', response.json);
                }
            }

            commit('toggleLoader', false);
        },

        async makeCashCollection({ commit }, data) {
            commit('toggleLoader', true);

            const { $json, id } = data;

            const response = await $json.post({
                url: `device/cash-collection/`,
                data: {
                    id
                }
            });

            commit('toggleLoader', false);

            return response;
        },

        async saveChangeDevice({ commit }, val) {
            const { $json, id, data } = val;
        },

        async deleteDevice({ dispatch }, data) {
            const { $json, id } = data;

            const response = {
                statusCode: 200,
                value: true,
            };
            return response;
        },

        // All Cards -----------------------------------------------------------------
        async getCards({ state, commit, dispatch }, $json) {
            commit('toggleLoader', true);

            const response = await $json.get('card');

            if (response.statusCode === 200) {
                commit('cardsData', response.json);
                dispatch('sortCards', {
                    mark: 'cardSerialNumber',
                    asc: true,
                });
            }

            commit('toggleLoader', false);
        },

        // sortCards({ state }, value) {
        //     const { mark, asc } = value;
        //     state.cards_data.sort((a, b) => {
        //         if (typeof state.cards_data[0][mark] == 'number') {
        //             if (asc) {
        //                 return a[mark] - b[mark];
        //             } else {
        //                 return b[mark] - a[mark];
        //             }
        //         } else {
        //             if (asc) {
        //                 return `${a[mark]}` > `${b[mark]}` ? 1 : -1;
        //             } else {
        //                 return `${a[mark]}` > `${b[mark]}` ? -1 : 1;
        //             }
        //         }
        //     });
        // },

        // filterCards({ state }, value) {
        //     state.search = value;
        //     const filterFields = ['cardSerialNumber', 'firstName', 'lastName', 'phoneNumber'];
        //     state.cards_data = state.temp_cards_data.filter((item) => {
        //         item = { ...item };
        //         let result = false;
        //         filterFields.forEach((element) => {
        //             if (result) return;
        //             const el = item[element].toLowerCase();
        //             result = el.includes(value.toLowerCase());
        //         });
        //         return result;
        //     });
        // },

        // Card ----------------------------------------------------------------------
        async getOneCard({ commit }, data) {
            commit('toggleLoader', true);

            const { $json, id } = data;

            if (id === 'new') {
                const newDate = new Date().toISOString();
                const card = {
                    id: 0,
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    cardSerialNumber: 0,
                    cardVisualNumber: '',
                    balance: 0,
                    createdTimestamp: newDate,
                    updatedTimestamp: newDate,
                };
                commit('setOneCard', card);
            } else {
                const response = await $json.get(`card/${id}`);

                if (response.statusCode === 200) {
                    const card = { ...response.json };

                    commit('setOneCard', card);
                }
            }

            commit('toggleLoader', false);
        },

        async saveChangeCard({ commit }, val) {
            const { $json, id, data } = val;
            const response = await $json.put(`card/${id}`, { ...data });

            if (response.statusCode === 200) {
                commit('setOneCard', data);
            }
        },

        async deleteCard({ dispatch }, data) {
            const { $json, id } = data;
            const url = `card/${id}`;

            const response = await $json.delete(url);

            if (response.statusCode === 200) {
                dispatch('getOneCard', $json);
            }
            return response;
        },

        // CardReplenishment ----------------------------------------------------------
        // async getCardsReplenishment({ dispatch, commit }, $json) {
        //     dispatch('cardsDateFilter', false);
        //     commit('toggleLoader', true);

        //     const response = await $json.get('cardreplenishment');

        //     if (response.statusCode === 200) {
        //         dispatch('cardsDateFilter', response.json);
        //     }

        //     commit('toggleLoader', false);
        // },

        // cardsFilterKey({ state, commit }, key) {
        //     if (key == state.action_key_list_cards_filter_date) {
        //         commit('setActionKeyListCardsFilterDate', '');
        //         commit('setListCardsFilterDate', []);
        //         commit('setActiveCardsRDateSort', '');
        //     } else {
        //         commit('setActionKeyListCardsFilterDate', key);
        //         commit('setListCardsFilterDate', key);
        //         commit('setActiveCardsRDateSort', key);
        //     }
        // },

        // cardsDateFilter({ state, commit }, json) {
        //     if (json) {
        //         // 1. отсортировать по дате
        //         const dateSort = json.sort((a, b) => {
        //             const previusDate = new Date(a.createdTimestamp).getTime();
        //             const nextDate = new Date(b.createdTimestamp).getTime();
        //             return previusDate > nextDate ? 1 : -1;
        //         });

        //         // 2. найти startDate и endDate
        //         dateSort.forEach((item, index) => {
        //             if (index == 0) {
        //                 commit('startDateDeviceOperations', item.createdTimestamp);
        //             }
        //             if (index == dateSort.length - 1) {
        //                 commit('endDateDeviceOperations', item.createdTimestamp);
        //             }
        //         });
        //     }

        //     // 3. сделать список дат от startDate к endDate
        //     function getNextDateDay(date) {
        //         const oneDay = 60 * 60 * 24;
        //         const thisDay = new Date(date).getTime();
        //         const nextDay = new Date(thisDay + oneDay * 1000);
        //         const y = nextDay.getFullYear();
        //         let m = nextDay.getMonth() + 1;
        //         if (m < 10) m = `0${m}`;
        //         let d = nextDay.getDate();
        //         if (d < 10) d = `0${d}`;
        //         return `${y}-${m}-${d}`;
        //     }

        //     function changeStartDateDay(day) {
        //         const oneDay = 60 * 60 * 24;
        //         const thisDay = new Date(state.start_date_device_operations).getTime();
        //         const mondayStartWeak = new Date(thisDay - oneDay * 1000 * day);
        //         const y = mondayStartWeak.getFullYear();
        //         let m = mondayStartWeak.getMonth() + 1;
        //         if (m < 10) m = `0${m}`;
        //         let d = mondayStartWeak.getDate();
        //         if (d < 10) d = `0${d}`;
        //         return `${y}-${m}-${d}`;
        //     }
        //     const day = new Date(state.start_date_device_operations).getDay();
        //     commit('startDateDeviceOperations', changeStartDateDay(day ? day - 1 : 6));

        //     const cardsDF = {};
        //     let date = state.start_date_device_operations;
        //     cardsDF[date] = { list: [], active: false };

        //     while (new Date(date) < new Date(state.end_date_device_operations)) {
        //         date = getNextDateDay(date);
        //         cardsDF[date] = { list: [], active: false };
        //     }

        //     // сделать цікл по дням начиная с startDateDeviceOperations и заканчивая endDateDeviceOperations

        //     // let timestamp = dateSort[0].createdTimestamp.substring(0, 10);

        //     // cardsDF[timestamp] = { list: [], active: false };

        //     // dateSort.forEach((item) => {
        //     //     if (timestamp != item.createdTimestamp.substring(0, 10)) {
        //     //         timestamp = item.createdTimestamp.substring(0, 10);
        //     //         cardsDF[timestamp] = { list: [], active: false };
        //     //     }
        //     //     cardsDF[timestamp].list.push(item);
        //     // });

        //     commit('setCardsRDateSort', cardsDF);
        // },
    },
    modules: {},
});
